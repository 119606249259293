import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class TeamsService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'teams');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  getTeams(query?: any): Observable<any> {
    if (query == null || query == undefined ) {
      query = {
        page:0,
        size:10000
      }
    }
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] != null) {
          params = params.append(val, query[val]);
        }
      });
    }

    let tenantId = localStorage.getItem('tenant');
    params = params.append('tenantId', tenantId);
    console.log("adding param tenant " + tenantId);
    const url = `${this.base_url}/getTeams`;
    return this.http.get<any>(url, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }


  getPossibleAssignees(ticketId: any): Observable<any> {

    let params = {

    }
    if (ticketId != null) {
      params = {
        ticketId: ticketId
      }
    }

    const url = `${this.base_url}/getPossibleAssignees`;
    return this.http.get<any>(url,{params:params, headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  queryUsers(query: string, useTenant: boolean): Observable<any> {
    let params = {
    }
    if (useTenant) {
      params = {
        query : query,
        tenantId : localStorage.getItem('tenant')
      }
    } else {
      params = {
        query : query
      }
    }

    const url = `${this.base_url}/queryUsers`;
    return this.http.get<any>(url,{params:params, headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  queryUsersAndTeams(query: string, useTenant: boolean, queryRepairCentres?: boolean, queryTechnicians?: boolean): Observable<any> {
    let params = {
    }
    if (useTenant) {
      params = {
        query : query,
        tenantId : localStorage.getItem('tenant')
      }
    } else {
      params = {
        query : query
      }
    }

    if (queryRepairCentres != null && queryRepairCentres != undefined && queryRepairCentres) {
      params['queryRepairCentres'] = true;
    }
    if (queryTechnicians != null && queryTechnicians != undefined && queryTechnicians) {
      params['queryTechnicians'] = true;
    }

    const url = `${this.base_url}/queryUsersAndTeams`;
    return this.http.get<any>(url,{params:params, headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  createTeam(team:any): Observable<any> {
    const url = `${this.base_url}/createTeam`;
    return this.http.post<any>(url,team,{ headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getTeam(id:number): Observable<any> {
    const url = `${this.base_url}/getTeam/${id}`;
    return this.http.get<any>(url,{ headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateTeam(teamId:number, team:any): Observable<any> {
    const url = `${this.base_url}/updateTeam/${teamId}`;
    return this.http.post<any>(url,team,{ headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getRepairCentresWithoutTeam(): Observable<any> {
    const url = `${this.base_url}/getRepairCentresWithoutTeam`;

    let params = {
      tenantId : localStorage.getItem('tenant')
    }

    return this.http.get<any>(url,{ params:params, headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  toggleTeamStatus(teamId:number): Observable<any> {
    const url = `${this.base_url}/toggleTeamStatus/${teamId}`;
    return this.http.post<any>(url, null ,{ headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  searchRoles(): Observable<any> {
    const url = `${this.base_url}/searchRoles/${localStorage.getItem('tenant')}`;
    return this.http.get<any>(url ,{ headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getUsersFromRole(roleId): Observable<any> {
    const url = `${this.base_url}/getUsersFromRole/${roleId}`;
    return this.http.get<any>(url ,{ headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }
}
