import {ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';


import {ToastrService} from "ngx-toastr";

import {Router} from "@angular/router";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {RequestTypeService} from "@appServices/core-ticketing/request-type.service";
import {RequestType} from "@appModels/ticketing/request-type";
import {TicketPriority} from "@appModels/ticketing/ticket.priority";
import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {SlaConfiguration} from "@appModels/ticketing/sla-configuration";
import {TeamListRow} from "@appModels/ticketing/team.list.row";
import {TeamsService} from "@appServices/core-ticketing/teams-service";

@Component({
  moduleId: module.id,
  templateUrl: './request-type-create.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./request-type-create.component.css', '../../../tailwind.css'],
  encapsulation: ViewEncapsulation.None

})
export class RequestTypeCreateComponent {

  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private ticketTypeService: TicketTypeService,
    private requestTypeService: RequestTypeService,
    private ticketingService: TicketingService,
    private router: Router,
    private teamService: TeamsService
  ) {

    this.ticketTypeService.getTicketTypes(true).subscribe(x => {
      this.ticketTypes = x;
    })

    this.ticketingService.getPriorities().subscribe(x => {
      this.ticketPriorities = x;
    })

    this.requestTypeService.getSlaTypes().subscribe(x => {
      this.slaTypes = x;
    })

    this.teamService.getTeams().subscribe(x => {
      this.teams = x.content;
    });

  }

  mainLoading = false;

  ticketTypes : BasePojo[] = [];
  ticketPriorities  : TicketPriority[] = [];
  slaTypes : BasePojo[] = [];
  teams : TeamListRow[];

  requestType : RequestType = {
    slaConfigurations: [],
    name:"",
    teamId:null,
    description:null
  }


  loadingCreate = false;
  createRequestType() {
    if (this.requestType.name == null || this.requestType.name == undefined || this.requestType.name.length < 3) {
      this.toastrService.error("Please enter a longer name");
      return;
    }

    if (this.requestType.teamId == null || this.requestType.teamId == undefined) {
      this.toastrService.error("Please enter a default team");
      return;
    }


    if (this.requestType.ticketTypeId == null || this.requestType.ticketTypeId == undefined) {
      this.toastrService.error("Please select a ticket type");
      return;;
    }

    this.loadingCreate = true;


    this.requestTypeService.createRequestType(this.requestType).subscribe(x => {
      this.toastrService.success("New Request Type Created");
      this.loadingCreate = false;
      this.router.navigateByUrl(`/ticketing/requesttypes`);

    })
  }



  slaConfiguration : SlaConfiguration = {
    slaTypeId : null,
    days : 0,
    hours : 0,
    minutes : 0,
    ticketPriorityId : null
  }

  addSlaConfiguration() {

    if (this.slaConfiguration.days == null || this.slaConfiguration.days == undefined) {
      this.slaConfiguration.days = 0;
    }
    if (this.slaConfiguration.hours == null || this.slaConfiguration.hours == undefined) {
      this.slaConfiguration.hours = 0;
    }
    if (this.slaConfiguration.minutes == null || this.slaConfiguration.minutes == undefined) {
      this.slaConfiguration.minutes = 0;
    }

    if (this.slaConfiguration.days < 0 || this.slaConfiguration.hours < 0 || this.slaConfiguration.minutes < 0) {
      this.toastrService.error("Please choose a valid SLA time");
      return;
    }
    if (this.slaConfiguration.days == 0 && this.slaConfiguration.hours == 0 && this.slaConfiguration.minutes == 0) {
      this.toastrService.error("Please choose a valid SLA time");
      return;
    }

    if (this.slaConfiguration.slaTypeId == null || this.slaConfiguration.slaTypeId == undefined) {
      this.toastrService.error("Please choose the SLA Criteria");
      return;
    }
    if (this.slaConfiguration.ticketPriorityId == null || this.slaConfiguration.ticketPriorityId == undefined) {
      this.toastrService.error("Please choose the Ticket Priority");
      return;
    }

    if (this.requestType.slaConfigurations.some(x => x.slaTypeId == this.slaConfiguration.slaTypeId && x.ticketPriorityId == this.slaConfiguration.ticketPriorityId)) {
      this.toastrService.error("There already exists an SLA configuration for this Criteria and Priority");
      return;
    }

    for (let i = 0 ; i < this.ticketPriorities.length; i++) {
      if (this.slaConfiguration.ticketPriorityId == this.ticketPriorities[i].id) {
        this.slaConfiguration.ticketPriorityName = this.ticketPriorities[i].name;
      }
    }

    for (let i = 0 ; i < this.slaTypes.length; i++) {
      if (this.slaConfiguration.slaTypeId == this.slaTypes[i].id) {
        this.slaConfiguration.slaTypeName = this.slaTypes[i].name
      }
    }

    this.slaConfiguration.timeToCompleteString = this.slaConfiguration.days + " Days " + this.slaConfiguration.hours + " Hours " + this.slaConfiguration.minutes + " Minutes";

    this.requestType.slaConfigurations.push(JSON.parse(JSON.stringify(this.slaConfiguration)));

    this.slaConfiguration = {
      slaTypeId : null,
      days : 0,
      hours : 0,
      minutes : 0,
      ticketPriorityId : null
    }
  }


  removeSlaConfiguration(slaConfig : SlaConfiguration){

    this.requestType.slaConfigurations = this.requestType.slaConfigurations.filter(x => x.slaTypeId != slaConfig.slaTypeId || x.ticketPriorityId != slaConfig.ticketPriorityId);

  }
}
