import { featureFlags } from "./features";

export interface INavItem {
  path: string;
  title: string;
  icon: string;
  class: string;
  moduleName: string;
  permission: string;
  isFeatureEnabled?: boolean;
  subMenu: Partial<INavItem>[];
}
export type MainMenuType =
  | "crm"
  | "identity"
  | "inventory"
  | "setup"
  | "fieldforce"
  | "reports"
  | "finance"
  | "warrantyandservice"
  | "knowledge"
  | "globalsettings"
  | "communication"
  | "ticketing";
export type ModuleNavType = "logo";
export interface INav {
  main: Record<MainMenuType, INavItem[]>;
  moduleNav: Record<ModuleNavType, INavItem[]>;
  subNav: any;
}
export const nav = {
  main: {
    crm: [
      {
        path: "/crm/dashboard",
        title: "Dashboard",
        icon: "dashboard_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-crm-dashboard",
        subMenu: [],
      },
      {
        path: "customers",
        title: "Customers",
        icon: "register_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-customers",
        isFeatureEnabled: featureFlags.enableCustomerFilter,
        subMenu: [
          {
            path: "/crm/customers",
            title: "Customers",
            icon: "",
            class: "",
            permission: "can-view-customers-list",
          },
          {
            path: "/crm/customers/leads",
            title: "Leads",
            icon: "",
            class: "",
            permission: "can-view-lead-customers",
          },
          {
            path: "/crm/customers/incomplete-profiles",
            title: "Incomplete Profiles",
            icon: "",
            class: "",
            permission: "can-view-incomplete-profile-customers",
          },
          {
            path: "/crm/customers/awaiting-review",
            title: "Awaiting More Info",
            permission: "can-view-awaiting-more-info-customers",
            icon: "",
            class: "",
          },
          {
            path: "/crm/portable-customers",
            title: "Portable Customers",
            permission: "can-view-portable-customers",
            icon: "",
            class: "",
          },
          {
            path: "/crm/cco-leads",
            title: "CCO Leads",
            permission: "can-view-customers-kyc-zones",
            icon: "",
            class: "",
            isFeatureEnabled: featureFlags.enableCCOLeads,
          },
        ],
      },
      {
        path: "",
        title: "Escalations",
        icon: "escalate_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-escalations",
        subMenu: [
          {
            path: "/crm/escalations/customer-update",
            title: "Customer Update",
            permission: "can-view-customer-update-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/product-return",
            title: "Product Return",
            permission: "can-view-product-return-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/payment-reversal",
            title: "Payment Reversal",
            permission: "can-view-reversals",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/payment-support",
            title: "Payment Support",
            permission: "can-view-payment-support-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/accessory-sale",
            title: "Accessory Sale",
            permission: "can-view-accessory-sale-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/sales-agent",
            title: "Sales Agent",
            permission: "can-view-sale-agent-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/token-request",
            title: "Token Request",
            permission: "can-view-token-request-escalation",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/buyout-checks",
            title: "Buyout Checks",
            permission: "can-manage-generation-token-escalation",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/warranty-extension",
            title: "Warranty Extension",
            permission: "can-view-warranty-extension-escalation",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/rate-plan-change",
            title: "Rate Plan Change",
            permission: "can-view-rate-plan-change",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/grace-days",
            title: "Grace Days",
            permission: "can-view-gracedays-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/awaiting-review",
            title: "Awaiting Review",
            permission: "can-view-awaiting-review-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/upsell",
            title: "Upsell",
            permission: "can-view-upsell-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/telesales",
            title: "Telesales",
            permission: "",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/assigned-telesales",
            title: "Assigned Telesales",
            permission: "can-view-assigned-telesales",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/deposit-refund",
            title: "Deposit Refund",
            permission: "can-view-customer-deposit-refund-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/write-off",
            title: "Contract Write Off",
            permission: "can-approve-reject-write-off-contract",
            icon: "",
            class: "",
          },
          {
            path: "/crm/escalations/product-replace",
            title: "Product Replace",
            permission: "can-approve-reject-unlike-swap",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "",
        title: "NPS Surveys",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-see-nps-survey",
        subMenu: [
          {
            path: "/crm/nps-survey",
            title: "NPS Surveys",
            permission: "",
          },
        ],
      },
      {
        path: "/crm/held-payments",
        title: "Held Payments",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-held-payments",
        subMenu: [],
      },
      {
        path: "/crm/failed-payments",
        title: "Failed Payments",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-failed-payments",
        subMenu: [],
      },
      {
        path: "/crm/reprocess-payments",
        title: "Reprocess Payments",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-reprocess-payments",
        subMenu: [],
      },
      {
        path: "/crm/payoff-candidates",
        title: "Pay-Off Candidates",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-see-cashback-candidates",
        subMenu: [],
      },
      {
        path: "/crm/product-activation",
        title: "Product Activation",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-activate-product",
        subMenu: [],
      },
      {
        path: "/crm/rekey-token",
        title: "Rekey Token",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-rekey-tokens",
        subMenu: [],
      },

      {
        path: "/crm/phone-management",
        title: "Phone Management",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-rekey-tokens",
        subMenu: [],
      },
      {
        path: "/crm/payment-search",
        title: "Search Payments",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-payment-search",
        subMenu: [],
      },
      {
        path: "/crm/call-logs",
        title: "Call Logs",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-call-logs",
        subMenu: [],
      },
      {
        path: "/crm/interactions",
        title: "Interactions",
        icon: "group_icon",
        class: "",
        moduleName: "CRM",
        permission: "can-view-interactions",
        subMenu: [],
      },
      {
        path: "/crm/reports",
        title: "Reports",
        icon: "reports_icons",
        class: "",
        moduleName: "CRM",
        permission: "can-view-crm-reports",
        subMenu: [],
      },
    ],
    identity: [
      {
        path: "/identity-management/dashboard",
        title: "Dashboard",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Identity Management",
        permission: "can-view-identity-management-dashboards",
        subMenu: [],
      },
      {
        path: "/identity-management/users",
        title: "User Management",
        icon: "group_icon",
        class: "",
        moduleName: "Identity Management",
        permission: "can-view-users",
        subMenu: [],
      },
      {
        path: "/identity-management/roles",
        title: "Role Management",
        icon: "escalate_icon",
        class: "",
        moduleName: "Identity Management",
        permission: "can-view-roles",
        subMenu: [],
      },
      {
        path: "/identity-management/pcg",
        title: "PCG Management",
        icon: "group_icon",
        class: "",
        moduleName: "Identity Management",
        permission: "can-view-pcg",
        subMenu: [],
      },
      {
        path: "/identity-management/reports",
        title: "Reports",
        icon: "reports_icons",
        class: "",
        moduleName: "Identity Management",
        permission: "can-view-identity-management-reports",
        subMenu: [],
      },
    ],
    inventory: [
      {
        path: "/inventory/dashboard",
        title: "Dashboard",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-inventory-dashboard",
        subMenu: [],
      },
      {
        path: "/inventory/stocking-points",
        title: "Stocking Points",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-stocking-points",
        subMenu: [],
      },
      {
        path: "",
        title: "Quarantine",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-add-quarantine",
        subMenu: [
          {
            path: "/inventory/quarantine/",
            title: "Add Quarantine",
            permission: "can-quarantine-products",
            icon: "",
            class: "",
          },
          {
            path: "/inventory/remove-quarantine/",
            title: "Remove Quarantine",
            permission: "can-remove-from-quarantine",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "",
        title: "Orders",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "",
        subMenu: [
          {
            path: "",
            title: "Create Order",
            permission: "",
            icon: "",
            class: "",
            subMenu: [
              {
                path: "/inventory/replenishment/order",
                title: "RD/EC Order",
                permission: "can-create-replenishment-order",
                icon: "",
                class: "",
              },
              {
                path: "/inventory/consignment/order",
                title: "Transfer Order",
                permission: "can-create-transfer-order",
                icon: "",
                class: "",
              },
              {
                path: "/inventory/part-order/add",
                title: "Part Order",
                permission: "can-create-spare-parts-order",
                icon: "",
                class: "",
              },
              {
                path: "/inventory/portable/order",
                title: "Portable Order",
                permission: "can-create-portable-order",
                isFeatureEnabled: featureFlags.enablePortableOrder,
                icon: "",
                class: "",
              },
            ],
          },
          {
            path: "",
            title: "View Order",
            permission: "",
            icon: "",
            class: "",
            subMenu: [
              {
                path: "/inventory/replenishment",
                title: "Replenishment Orders",
                permission: "can-view-replenishment-orders",
                icon: "",
                class: "",
              },
              {
                path: "/inventory/product-order",
                title: "Stock Orders",
                permission: "can-view-product-orders",
                icon: "",
                class: "",
              },
              {
                path: "/inventory/part-order/approve",
                title: "Part Orders",
                permission: "can-approve-spare-parts-orders",
                icon: "",
                class: "",
              },
              {
                path: "/inventory/portable",
                title: "Portable Orders",
                permission: "can-view-portable-orders",
                icon: "",
                class: "",
              },
            ],
          },
          {
            path: "",
            title: "Warehouse Order",
            permission: "can-view-warehouse-orders",
            icon: "",
            class: "",
            subMenu: [
              {
                path: "/inventory/warehouse-order",
                title: "Pending",
                permission: "can-view-warehouse-orders",
                icon: "",
                class: "",
              },
              // {
              //   path: '',
              //   title: 'In Transit',
              //   permission: '',
              //   icon: '',
              //   class: ''
              // },
              // {
              //   path: '',
              //   title: 'Closed',
              //   permission: '',
              //   icon: '',
              //   class: ''
              // },
            ],
          },
        ],
      },
      {
        path: "/inventory/consignments",
        title: "Consignments",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-consignments",
        subMenu: [],
      },
      {
        path: "/inventory/shipping-companies",
        title: "Shipping Companies",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-shipping-companies",
        subMenu: [],
      },
      {
        path: "/inventory/tenant-inventory",
        title: "Tenant Inventory",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-tenant-inventory",
        subMenu: [],
      },
      {
        path: "/inventory/consignment-product-accept-receive-status",
        title: "Accept/Reject Reasons",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-consignment-product-accept-reject-statuses",
        subMenu: [],
      },
      {
        path: "/inventory/repair-centre-inventory",
        title: "Repair Centre Inventory",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-repair-centre-inventory",
        subMenu: [],
      },
      {
        path: "/inventory/migration",
        title: "Migrate Inventory",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-inventory-migration",
        subMenu: [],
      },
      {
        path: "/inventory/reports",
        title: "Reports",
        icon: "reports_icons",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-inventory-reports",
        subMenu: [],
      },
      {
        path: "/inventory/search",
        title: "Search",
        icon: "search_icon",
        class: "",
        moduleName: "Inventory",
        subMenu: [],
      },
      {
        path: "/inventory/product-conversion",
        title: "Product Conversion",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-convert-products",
        subMenu: [],
      },
      {
        path: "/inventory/change_product_status",
        title: "Change Product Status",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-change-product-status",
        subMenu: [],
      },
      {
        path: "",
        title: "Inventory Reconciliations",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-execute-reconciliations",
        subMenu: [
          {
            path: "/inventory/reconciliation/start",
            title: "Start Reconciliation",
            permission: "can-execute-reconciliations",
            icon: "",
            class: "",
          },
          {
            path: "/inventory/reconciliation/field-escalations",
            title: "Field Escalations",
            permission: "can-execute-reconciliations",
            icon: "",
            class: "",
          },
          {
            path: "/inventory/reconciliation/history",
            title: "Reconciliation History",
            permission: "can-execute-reconciliations",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "",
        title: "Inventory Escalations",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "can-view-inventory-escalations",
        subMenu: [
          {
            path: "/inventory/escalation/reconciliation",
            title: "Reconciliation Escalations",
            permission: "can-view-reconciliation-escalations",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "",
        title: "Inventory Recovery",
        icon: "group_icon",
        class: "",
        moduleName: "Inventory",
        permission: "",
        subMenu: [
          {
            path: "/inventory/escalation/billable",
            title: "Billable Recovery",
            permission: "",
            icon: "",
            class: "",
          },
        ],
      },
    ],
    setup: [
      {
        path: "/setup/dashboard",
        title: "Dashboard",
        icon: "dashboard_icon",
        class: "",
        subMenu: [],
        permission: "can-view-setup-dashboard",
        moduleName: "Setup",
      },
      {
        path: "finance",
        title: "Finance",
        icon: "group_icon",
        class: "",
        permission: "can-view-rates",
        moduleName: "Setup",
        subMenu: [
          {
            path: "/setup/replacement-rate/registration",
            title: "Replacement Rates",
            icon: "",
            class: "",
            permission: "can-create-replacement-rate",
          },
          {
            path: "/setup/replacement-rate-sku-mappings",
            title: "SKU Replacement",
            icon: "reports_icons",
            class: "",
            permission: "can-view-replacement-rate-sku-mappings",
          },
          {
            path: "/setup/rates",
            title: "Product Rates",
            icon: "",
            class: "",
            permission: "can-view-rates",
          },
          {
            path: "/setup/accessory-rates",
            title: "Accessory Rates",
            icon: "",
            class: "",
            permission: "can-view-accessory-rates",
          },
          {
            path: "/setup/upsell-offer",
            title: "Upsell Offers",
            icon: "",
            class: "",
            permission: "can-view-upsell-offers",
            isFeatureEnabled: featureFlags.enableUpsellOffers,
          },
          {
            path: "/setup/delinquency-definition",
            title: "Delinquency Definition",
            icon: "",
            class: "",
            permission: "can-view-delinquency-definitions",
          },
          {
            path: "/setup/payment-categorization",
            title: "Recent Payments Category",
            icon: "",
            class: "",
            permission: "can-view-payment-categorizations",
          },
          {
            path: "/setup/vat-configurations",
            title: "VAT Configuration",
            icon: "",
            class: "",
            permission: "can-view-vat-configurations",
          },
          {
            path: "/setup/eligibility-definition",
            title: "Eligibility Definition",
            icon: "",
            class: "",
            permission: "can-view-eligibility-definitions",
          },
          {
            path: "/setup/cogs",
            title: "Cogs",
            icon: "",
            class: "",
            permission: "can-view-cogs",
          },
          {
            path: "/setup/pre-token-channel",
            title: "Pre Token Channel",
            icon: "",
            class: "",
            permission: "can-view-pre-token-channel",
          },
        ],
      },
      {
        path: "",
        title: "Smile ID",
        icon: "group_icon",
        class: "",
        permission: "can-view-smile-id-product-check",
        moduleName: "Setup",
        isFeatureEnabled: featureFlags.enableSmileIdProductCheck,
        subMenu: [
          {
            path: "/setup/smile-id/product-checks",
            title: "Smile ID Product Checks",
            icon: "",
            class: "",
            permission: "",
          },
        ],
      },
      {
        path: "crb",
        title: "CRB",
        icon: "group_icon",
        class: "",
        permission: "can-view-crb-product-checks",
        moduleName: "Setup",
        isFeatureEnabled: featureFlags.enableCRBProductChecks,
        subMenu: [
          {
            path: "/setup/crb/product-checks",
            title: "CRB Product Checks",
            icon: "",
            class: "",
            permission: "",
          },
        ],
      },
      {
        path: "efris",
        title: "Efris",
        icon: "group_icon",
        class: "",
        permission: "can-view-efris",
        moduleName: "Setup",
        subMenu: [
          {
            path: "/setup/efris/goods",
            title: "Goods",
            icon: "",
            class: "",
            permission: "can-view-efris",
          },
          {
            path: "/setup/efris/categories",
            title: "Categories",
            icon: "",
            class: "",
            permission: "can-view-efris",
          },
          {
            path: "/setup/efris/creditnotes",
            title: "Credit notes",
            icon: "",
            class: "",
            permission: "can-view-efris",
          },
        ],
      },
      {
        path: "/setup/product-catalogues",
        title: "Product Catalogues",
        icon: "group_icon",
        class: "",
        permission: "can-view-product-catalogues",
        moduleName: "Setup",
        subMenu: [],
      },
      {
        path: "/setup/loyalty-points",
        title: "Loyalty Points",
        icon: "group_icon",
        class: "",
        permission: "can-view-streak-config",
        moduleName: "Setup",
        isFeatureEnabled: featureFlags.enableLoyaltyPoints,
        subMenu: [
          {
            path: "/setup/loyalty-points/service",
            title: "Loyalty Points Service",
            icon: "",
            class: "",
            permission: "can-view-loyalty-service",
            isFeatureEnabled: featureFlags.enableLoyaltyPointsManagement,
          },
          {
            path: "/setup/loyalty-points/tiers",
            title: "Loyalty Tiers",
            icon: "",
            class: "",
            permission: "can-view-loyalty-tiers",
            isFeatureEnabled: featureFlags.enableLoyaltyPointsManagement,
          },
            {
            path: '/setup/loyalty-points/enroll-config',
            title: 'Enrollment Config',
            icon: '',
            class: '',
            permission: 'can-view-welcome-configuration',
          },
          {
            path: "/setup/loyalty-points/product-config",
            title: "Product Config",
            icon: "",
            class: "",
            permission: "can-view-product-config",
            isFeatureEnabled: featureFlags.enableProductConfig,
          },
          {
            path: "/setup/loyalty-points/combo-config",
            title: "Combo config",
            icon: "",
            class: "",
            permission: "can-view-combo-config",
          },
          {
            path: "/setup/loyalty-points/streak-config",
            title: "Bonus Config",
            icon: "",
            class: "",
            permission: "can-view-streak-config",
            isFeatureEnabled: featureFlags.enableStreakConfig,
          },
          // {
          //   path: '/setup/loyalty-points/welcome-config',
          //   title: 'Welcome Config',
          //   icon: '',
          //   class: '',
          //   permission: 'can-view-welcome-config',
          // },
          {
            path: "/setup/loyalty-points/redeem-config",
            title: "Loyalty Tokens",
            icon: "",
            class: "",
            permission: "can-view-loyalty-token-redeem",
            isFeatureEnabled: featureFlags.enableLoyaltyTokens,
          },

          {
            path: "/setup/loyalty-points/loyalty-discounts",
            title: "Loyalty Discounts",
            icon: "",
            class: "",
            permission: "can-view-loyalty-discount",
          },
        ],
      },
      {
        path: "/setup/sku-digital-contract-management",
        title: "SKU Digital Contract Management",
        icon: "group_icon",
        class: "",
        permission: "can-manage-digital-contract-sku",
        moduleName: "Setup",
        subMenu: [],
      },
      {
        path: "/setup/pos-device-whitelisting",
        title: "POS Device Whitelisting",
        icon: "group_icon",
        class: "",
        permission: "can-manage-pos-device-whitelisting",
        moduleName: "Setup",
        subMenu: [],
      },
      {
        path: "/setup/parts",
        title: "Parts",
        icon: "group_icon",
        class: "",
        permission: "can-view-parts",
        moduleName: "Setup",
        subMenu: [
          {
            path: "/setup/parts",
            title: "Global Parts",
            icon: "",
            class: "",
            permission: "can-view-globalparts",
          },
          {
            path: "/setup/parts/tenant-parts",
            title: "Tenant Parts",
            icon: "",
            class: "",
            permission: "can-view-tenantparts",
          },
        ],
      },
      {
        path: "general",
        title: "General",
        icon: "register_icon",
        class: "",
        moduleName: "Setup",
        permission: "can-view-general-settings",
        subMenu: [
          {
            path: "/setup/countries",
            title: "Countries",
            icon: "",
            class: "",
            permission: "can-view-country",
          },
          {
            path: "/setup/admin-zones",
            title: "Admin Zones",
            icon: "",
            class: "",
            permission: "can-view-admin-zones",
          },
          {
            path: "/setup/currencies",
            title: "Currencies",
            icon: "",
            class: "",
            permission: "can-view-currency",
          },
          {
            path: "/setup/languages",
            title: "Languages",
            icon: "",
            class: "",
            permission: "can-view-language",
          },
          {
            path: "/setup/timezones",
            title: "Timezones",
            icon: "",
            class: "",
            permission: "can-view-timezone",
          },
          {
            path: "/setup/time-intervals",
            title: "Time Interval",
            icon: "",
            class: "",
            permission: "can-view-time-interval",
          },
          {
            path: "/setup/custom-regions",
            title: "Custom Sales Regions",
            icon: "",
            class: "",
            permission: "can-view-custom-regions",
          },
          {
            path: "/setup/aging-stock-threshold",
            title: "Aging Stock Threshold",
            icon: "",
            class: "",
            permission: "",
          },
          {
            path: "/setup/consignment-rejection-disposition",
            title: "Rejection Disposition",
            icon: "",
            class: "",
            permission: "",
          },
          {
            path: "/setup/threshold",
            title: "Threshold",
            icon: "",
            class: "",
            permission: "can-view-threshold",
          },
          {
            path: "/setup/commission-payout-fund",
            title: "Commission Payout Fund",
            icon: "",
            class: "",
            permission: "can-view-commission-payout-fund",
          },
        ],
      },
      {
        path: "",
        title: "Inventory",
        icon: "group_icon",
        class: "",
        moduleName: "Field Force",
        permission: "can-view-inventory-preferences",
        subMenu: [
          {
            path: "/setup/inventory-preferences",
            title: "Inventory Preferences",
            icon: "",
            class: "can-view-inventory-preferences",
          },
          {
            path: "/setup/inventory-control/sku-config",
            title: "Inventory Control",
            icon: "",
            class: "can-view-inventory-sku-config",
          },
          {
            path: "/setup/inventory-zone-restriction",
            title: "Inventory Zone Restriction",
            icon: "",
            class: "",
          },
          {
            path: "/setup/aged-in-trade",
            title: "Aged in Trade",
            icon: "",
            permission: "can-manage-aged-in-trade",
            isFeatureEnabled: featureFlags.enableAgedInTrade,
          },
          {
            path: "/setup/lost-in-trade",
            title: "Lost in Trade",
            icon: "",
            permission: "can-manage-lost-in-trade",
            isFeatureEnabled: featureFlags.enableLostInTrade,
          },
        ],
      },
      {
        path: "/fieldforce/sales-staff",
        title: "Field Force Staff",
        icon: "group_icon",
        class: "",
        moduleName: "Field Force",
        permission: "can-view-fieldforce-sales-staff-definition",
        subMenu: [
          {
            path: "/setup/field-force-definitions",
            title: "Field Force Staff Levels",
            icon: "",
            class: "",
          },
          {
            path: "/setup/field-force-entity-preference",
            title: "Field Force Search Preference",
            icon: "",
            class: "",
            permission: "can-view-field-force-search-preferences",
          },
          {
            path: "/setup/field-force-document-attachments",
            title: "Recruitment Document Attachments",
            icon: "",
            class: "",
            isFeatureEnabled: featureFlags.enableFieldForceAttachments,
          },
          {
            path: "/setup/custom-fields",
            title: "Recruitment Custom Fields",
            icon: "",
            class: "",
            isFeatureEnabled: featureFlags.enableFieldForceAttachments,
          },
        ],
      },
      {
        path: "crm",
        title: "CRM",
        icon: "register_icon",
        class: "",
        moduleName: "Setup",
        permission: "can-view-customize-fields",
        subMenu: [
          {
            path: "customize-fields",
            title: "Customize Fields",
            icon: "",
            class: "",
            subMenu: [
              {
                path: "/setup/tenant-custom-fields",
                title: "Custom Fields",
                icon: "",
                class: "",
                permission: "can-view-tenant-custom-fields",
              },
              {
                path: "/setup/tenant-default-fields",
                title: "Default Fields",
                icon: "",
                class: "",
                permission: "can-view-tenant-default-fields",
              },
            ],
          },
          {
            path: "/setup/status",
            title: "Customer Status",
            icon: "",
            class: "",
            permission: "can-view-customer-status",
          },
          {
            path: "/setup/identification-type",
            title: "Identification Type",
            icon: "",
            class: "",
            permission: "can-view-identification-type",
          },
          {
            path: "/setup/customer-deposit-refund",
            title: "Customer Deposit Refund",
            icon: "",
            class: "",
            permission: "can-view-deposit-refund",
          },
          {
            path: "/setup/document-attachment",
            title: "Document Attachment",
            icon: "",
            class: "",
            permission: "can-view-document-attachment",
          },
          {
            path: "/setup/verification-reason",
            title: "Verification Reason",
            icon: "",
            class: "",
            permission: "",
            subMenu: [
              {
                path: "/setup/verification-reason/rejected",
                title: "Rejected Reasons",
                icon: "",
                class: "",
                permission: "",
              },
              {
                path: "/setup/verification-reason/blacklisted",
                title: "Blacklisted Reasons",
                icon: "",
                class: "",
                permission: "",
              },
            ],
          },
        ],
      },
      {
        path: "aftersales",
        title: "Warranty & Service",
        icon: "register_icon",
        class: "",
        moduleName: "Setup",
        permission: "can-setup-warrany-items",
        subMenu: [
          {
            title: "Customer Tickets",
            icon: "group_icon",
            class: "",
            permission: "",
            subMenu: [
              {
                path: "/setup/customer-ticket/ticket-source",
                title: "Customer Ticket Source",
                icon: "",
                class: "",
                permission: "",
              },
              {
                path: "/setup/customer-ticket/ticket-type",
                title: "Customer Ticket Type",
                icon: "",
                class: "",
                permission: "",
              },
            ],
          },
          {
            path: "/setup/ticketcreationfields",
            title: "Ticket Creation fields",
            icon: "",
            class: "",
            //permission: 'can-view-testflows',
          },
          {
            path: "/setup/priceconfiguration/",
            title: "OOW Prices",
            icon: "",
            class: "",
            permission: "can-setup-oow-prices",
            subMenu: [
              {
                path: "/setup/priceconfiguration/labour",
                title: "Labour Prices",
                icon: "",
                class: "",
                permission: "can-view-rc-types",
              },
              {
                path: "/setup/priceconfiguration/parts",
                title: "Part Prices",
                icon: "",
                class: "can-manage-part-prices",
              },
            ],
          },
          {
            path: "/setup/priceconfiguration/types",
            title: "Repair Centre Types",
            icon: "",
            class: "",
            permission: "can-view-rc-types",
          },
          /*      REMOVING FOR NOW   {
            path: '/setup/iw-claims/',
            title: 'IW Claims',
            icon: '',
            class: '',
            permission: 'can-setup-iw-claims'
          },*/
          // {
          //   path: '/setup/fault-action-setup',
          //   title: 'Product Faults/Actions setup',
          //   icon: '',
          //   class: '',
          //   permission: 'can-create-faults',
          // },
          {
            path: "/setup/customer-complaint-setup",
            title: "Customer Complaint Setup",
            icon: "",
            class: "",
            permission: "can-create-complaints",
          },
          {
            path: "/setup/oow-repair-conditions",
            title: "Conditions for Financing Repair Costs",
            permission: "can-setup-oow-repair-conditions",
            icon: "",
            class: "",
          },
          {
            path: "/setup/work-order-sla-conditions",
            title: "Work Order SLA setup",
            permission: "can-view-wo-sla-setup",
            icon: "",
            class: "",
          },
          {
            path: "/setup/ticket-sla-conditions",
            title: "Ticket SLA setup",
            permission: "can-view-ticket-sla-setup",
            icon: "",
            class: "",
          },
          {
            path: "/setup/tenant-calendar",
            title: "Tenant Calendar Setup",
            permission: "can-view-tenant-calendar",
            icon: "",
            class: "",
            subMenu: [
              {
                path: "/setup/tenant-calendar/workinghours",
                title: "Working Calendar",
                permission: "can-view-tenant-calendar",
                icon: "",
                class: "",
                moduleName: "Setup",
              },
              {
                path: "/setup/tenant-calendar/holidays",
                title: "Country Holidays",
                permission: "can-view-tenant-calendar",
                icon: "",
                class: "",
                moduleName: "Setup",
              },
            ],
          },
        ],
      },
      {
        path: "messaging",
        title: "Messaging",
        icon: "register_icon",
        class: "",
        moduleName: "Setup",
        permission: "can-view-messaging",
        subMenu: [
          {
            path: "sms",
            title: "SMS",
            icon: "",
            class: "",
            subMenu: [
              {
                path: "/setup/sms-providers",
                title: "SMS Providers",
                icon: "",
                class: "",
                permission: "can-view-sms-providers",
              },
              {
                path: "setup/sms-templates",
                title: "SMS Templates",
                icon: "template_icon",
                class: "",
                permission: "can-view-sms-templates",
              },
              {
                path: "setup/sms-messages",
                title: "SMS Messages",
                icon: "template_icon",
                class: "",
                permission: "can-view-messaging",
              },
            ],
          },
        ],
      },
      {
        path: "setup/commission-scheduler",
        title: "Commission Schedules",
        icon: "reports_icons",
        class: "",
        moduleName: "Setup",
        permission: "can-view-commission-schedules",
        subMenu: [],
      },
      {
        path: "setup/apk-version",
        title: "APK Versions",
        icon: "reports_icons",
        class: "",
        moduleName: "Setup",
        permission: "can-upload-pay-go-apk",
        subMenu: [],
      },
      {
        path: "setup/integrations-dashboard",
        title: "Integrations Dashboard",
        icon: "reports_icons",
        class: "",
        moduleName: "Setup",
        permission: "can-view-tenant-integrations-dashboard",
        subMenu: [],
      },
      {
        path: "reports",
        title: "Reports",
        icon: "reports_icons",
        class: "",
        moduleName: "Setup",
        permission: "can-view-setup-reports",
        subMenu: [],
      },
    ],
    fieldForce: [
      {
        path: "/fieldforce/sales-staff",
        title: "Field Force Staff",
        icon: "group_icon",
        class: "",
        moduleName: "Field Force",
        permission: "can-view-fieldforce-sales-staff",
        subMenu: [
          {
            path: "/fieldforce/sales-staff/staff",
            title: "Manage Field Force Staff",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/sales-staff/staff/product-point-categorization",
            title: "Field Force Staff Product Point",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/sales-staff/staff/segmentation",
            title: "Field Force Staff Segmentation",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/sales-staff/staff/update-line-manager",
            title: "Update Field Force Staff Line Managers",
            permission: "can-bulk-update-line-manager",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/sales-staff/staff/update-escalations",
            title: "Update Field Force Staff Escalations",
            permission: "can-view-sales-staff-update-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/sales-staff/staff/create-escalations",
            title: "Create Field Force Staff Escalations",
            permission: "can-view-sales-staff-create-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/sales-staff/staff/suspension-escalations",
            title: "Field Force Staff Suspension",
            permission: "can-view-sales-staff-suspension-escalations",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/sales-staff/sep-referral",
            title: "SEP Referral",
            permission: "can-view-fieldforce-sep-referral",
            icon: "",
            class: "",
            isFeatureEnabled: featureFlags.enableSepReferral,
          },
          {
            path: "/fieldforce/field-force-recruitments",
            title: "Fieldforce Recruitment",
            permission: "",
            icon: "",
            class: "",
            isFeatureEnabled: featureFlags.enableSepReferral,
          },
        ],
      },
      {
        path: "/fieldforce/incentivemanagement",
        title: "Incentive Management",
        icon: "group_icon",
        moduleName: "Field Force",
        permission: "can-view-fieldforce-incentivemanagement",
        class: "",
        subMenu: [
          {
            path: "/fieldforce/incentivemanagement/commission-list",
            title: "Commissions",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/incentivemanagement/commission-payout",
            title: "Commission Payout",
            icon: "",
            class: "can-view-commission-payout",
          },
          {
            path: "/fieldforce/incentivemanagement/commission-report",
            title: "Report",
            icon: "",
            class: "",
            permission: "can-view-commissionreport",
          },
          {
            path: "/fieldforce/incentivemanagement/commission/search",
            title: "Commissions Search",
            icon: "",
            class: "",
            permission: "can-view-commissionsearch",
          },
          {
            path: "/fieldforce/incentivemanagement/pos-settlement-price",
            title: "POS Settlement Price",
            icon: "",
            class: "",
            permission: "can-manage-pos-settlement",
          },
          {
            path: "/fieldforce/incentivemanagement/pos-settlement-escalation",
            title: "POS Settlement Escalations",
            icon: "",
            class: "",
            permission: "can-view-escalated-pos-settlements",
          },
        ],
      },
      {
        path: "",
        title: "Clawback Management",
        icon: "group_icon",
        moduleName: "Field Force",
        permission: "can-view-clawback-management",
        isFeatureEnabled: featureFlags.enableClawbackManagement,
        class: "",
        subMenu: [
          // {
          //   path: '/fieldforce/commission-clawback',
          //   title: 'Commission Clawback',
          //   icon: '',
          //   class: '',
          //   permission: '',
          // },
          {
            path: "/fieldforce/deposit-clawback",
            title: "Deposit Clawback",
            icon: "",
            class: "",
            permission: "",
          },
        ],
      },
      {
        path: "",
        title: "Route Management",
        icon: "group_icon",
        class: "",
        moduleName: "Field Force",
        permission: "can-view-route-management",
        subMenu: [
          {
            path: "/fieldforce/routes",
            title: "Routes",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/routes/outlets",
            title: "Outlets",
            icon: "",
            class: "",
          },
          {
            path: "/fieldforce/routes/pjp",
            title: "PJP",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "fieldforce/stock-recovery/registry",
        title: "Stock Recovery Registry",
        icon: "group_icon",
        class: "",
        moduleName: "Field Force",
        permission: "can-view-stock-recovery-registry",
        subMenu: [
          {
            path: "/fieldforce/stock-recovery/registry",
            title: "Manage Stock Recovery Registry",
            icon: "",
            permission: "",
            class: "",
          }
        ],
      },
      {
        path: "fieldforce/security-deposit/registry",
        title: "Security Deposit Registry",
        icon: "group_icon",
        class: "",
        moduleName: "Field Force",
        permission: "can-view-security-deposit-payments",
        isFeatureEnabled: featureFlags.enableSecurityDepositRegistry,
        subMenu: [
          {
            path: "/fieldforce/security-deposit/registry",
            title: "Security Deposit Payments",
            icon: "",
            permission: "",
            class: "",
          },
        ],
      },
      {
        path: "/fieldforce/held-payment",
        title: "Held Payments",
        icon: "group_icon",
        moduleName: "Field Force",
        permission: "can-view-fieldforce-held-payment",
        isFeatureEnabled: featureFlags.enableHeldPayments,
        class: "",
        subMenu: [],
      },
      {
        path: "/fieldforce/repaircentre-mapping",
        title: "Repair Centre",
        icon: "group_icon",
        class: "",
        moduleName: "Field Force",
        permission: "",
        subMenu: [],
      },

      {
        path: "/fieldforce/reports",
        title: "Reports",
        icon: "reports_icons",
        class: "",
        moduleName: "Field Force",
        permission: "can-view-fieldforce-reports",
        subMenu: [],
      },
    ],
    reporting: [
      {
        path: "/reporting/reports",
        title: "Reports",
        icon: "reports_icons",
        class: "",
        moduleName: "Reporting",
        permission: "can-view-reports",
        subMenu: [],
      },
      {
        path: "/reporting/manage-reports",
        title: "Manage reports",
        icon: "group_icon",
        class: "",
        moduleName: "Reporting",
        permission: "can-manage-reports",
        subMenu: [],
      },
    ],
    finance: [
      {
        path: "/finance/dashboard",
        title: "Dashboard",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-finance-dashboard",
        subMenu: [],
      },
      {
        path: "/finance/manual-payments",
        title: "Manual Payment",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-manual-payments",
        subMenu: [],
      },
      {
        path: "/finance/check-payments",
        title: "Check Payments",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "",
        subMenu: [
          {
            path: "/finance/check-payments/review-transactions",
            title: "Review Transactions",
            icon: "group_icon",
            class: "",
            moduleName: "Finance",
            permission: "",
            subMenu: [],
          },
          {
            path: "/finance/check-payments/configure-push-payments",
            title: "Push Payments",
            icon: "group_icon",
            class: "",
            moduleName: "Finance",
            permission: "",
            subMenu: [],
          },
        ],
      },
      {
        path: "/finance/reversed-payments",
        title: "Reversed Payments",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-reversals",
        subMenu: [],
      },
      {
        path: "/finance/payoff-candidates",
        title: "Pay-Off Candidates",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-see-cashback-candidates",
        subMenu: [],
      },
      {
        path: "/finance/payoff-candidates-summary",
        title: "Pay-Off Candidates Summary",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-see-cashback-candidates-summary",
        subMenu: [],
      },
      {
        path: "/finance/free-credits",
        title: "Free Credit Payment",
        icon: "escalate_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-free-credit-payments",
        subMenu: [],
      },
      {
        path: "/finance/free-purchase-credits",
        title: "Free Purchase Credit Payment",
        icon: "escalate_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-free-purchase-credit-payments",
        subMenu: [],
      },
      {
        path: "/finance/disburse-points",
        title: "Disburse Loyalty Points",
        icon: "escalate_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-finance-disburse-loyalty",
        subMenu: [],
      },
      {
        path: "/finance/insurance-compensation",
        title: "Insurance Compensation",
        icon: "escalate_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-insurance-compensation",
        subMenu: [],
      },
      {
        path: "/finance/loyalty-discounts-finance",
        title: "Loyalty Discounts",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-finance-view-loyalty-discounts",
        subMenu: [],
      },
      {
        path: "/finance/bulk-edit",
        title: "Bulk Buyout Edit",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-edit-bulk-buyout",
        subMenu: [],
      },
      {
        path: "/finance/bulk-suspension",
        title: "Bulk Suspension",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-bulk-suspension",
        subMenu: [],
      },
      {
        path: "/finance/bulk-writeoff",
        title: "Bulk Write Off",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-write-off-bulk-contract",
        subMenu: [],
      },
      {
        path: "/finance/contract-tagging",
        title: "Contract Tagging",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "",
        subMenu: [],
      },
      // {
      //   path: '/finance/escalations',
      //   title: ' Escalations',
      //   icon: 'escalate_icon',
      //   class: '',
      //   moduleName: 'Finance',
      //   permission: 'can-view-escalations',
      //   subMenu: []
      // },
      {
        path: "wallet",
        title: "Wallet Ledgers",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-view-wallet-ledgers",
        subMenu: [
          {
            path: "/finance/wallet-ledgers/customer-wallets",
            title: "Customer Wallets",
            icon: "",
            class: "",
          },
          {
            path: "/finance/wallet-ledgers/product-wallets",
            title: "Product Wallets",
            icon: "",
            class: "",
          },
          {
            path: "/finance/wallet-ledgers/promotion-wallets",
            title: "Promotion Wallet",
            icon: "",
            class: "",
          },
          {
            path: "/finance/wallet-ledgers/sales-agent-float-wallets",
            title: "Sales Agent Float Wallets",
            icon: "",
            class: "",
          },
          {
            path: "/finance/wallet-ledgers/partner-wallets",
            title: "Partner Wallets",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "/finance/mpesa-missed-payments",
        title: "Mpesa Missed Payments",
        icon: "group_icon",
        class: "",
        moduleName: "Finance",
        permission: "can-fetch-mpesa-missed-payments",
        subMenu: [],
      },
      {
        path: "/finance/reports",
        title: "Reports",
        icon: "reports_icons",
        class: "",
        moduleName: "Finance",
        permission: "can-view-finance-reports",
        subMenu: [],
      },
    ],
    warrantyandservice: [
      {
        path: "/warranty-and-service/dashboard",
        title: "Dashboard",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Warranty and Service",
        subMenu: [],
      },
      {
        path: "/warranty-and-service/product-activation",
        title: "Product Activation",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-activate-product",
        subMenu: [],
      },
      {
        path: "/warranty-and-service/repair_centre",
        title: "Repair Centre",
        icon: "group_icon",
        class: "",
        permission: "can-view-repair-centre",
        moduleName: "Warranty and Service",
        subMenu: [
          {
            path: "/warranty-and-service/repair_centre/list",
            title: "All Repair Centres",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "/warranty-and-service/ticket",
        title: "Tickets",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        subMenu: [
          {
            path: "/warranty-and-service/ticket/list",
            title: "All Tickets",
            icon: "",
            class: "",
          },
          {
            path: "/warranty-and-service/ticket/return",
            title: "Return/Repossession",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "/warranty-and-service/work_order",
        title: "Work Orders",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        subMenu: [
          {
            path: "/warranty-and-service/work_order",
            title: "All Work Orders",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "/warranty-and-service/repair_centre/inventory",
        title: "Repair Centre Inventory",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        subMenu: [
          {
            path: "/warranty-and-service/repair_centre/inventory",
            title: "Report",
            icon: "group_icon",
            class: "",
            moduleName: "Warranty and Service",
          },
          {
            path: "/warranty-and-service/repair_centre/received",
            title: "Received Items (RMA)",
            icon: "group_icon",
            class: "",
            permission: "can-view-repair-centre-inventory",
            moduleName: "Warranty and Service",
          },
          {
            path: "/warranty-and-service/repair_centre/faulty",
            title: "Faulty Items",
            icon: "group_icon",
            class: "",
            permission: "can-view-repair-centre-inventory",
            moduleName: "Warranty and Service",
          },
          {
            path: "/warranty-and-service/repair_centre/shipments",
            title: "Inbound/Outbound Shipments",
            icon: "group_icon",
            class: "",
            permission: "can-view-repair-centre-shipment",
            moduleName: "Warranty and Service",
          },
          {
            path: "",
            title: "Adjustments",
            icon: "group_icon",
            class: "",
            moduleName: "Warranty and Service",
            subMenu: [
              {
                path: "/warranty-and-service/repair_centre/new_adjustment",
                title: "New Adjustment",
                icon: "",
                class: "",
              },
              {
                path: "/warranty-and-service/repair_centre/adjustment_history",
                title: "History",
                icon: "",
                class: "",
              },
            ],
          },
        ],
      },
      {
        path: "/warranty-and-service/product-recharge-management",
        title: "Product Recharge Management",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-manage-recharge-dates",
        subMenu: [],
      },
      {
        path: "/warranty-and-service/held-oow-payments",
        title: "Held OOW Payments",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-view-oow-held-payments",
        subMenu: [],
      },
      {
        path: "/warranty-and-service/payment-search",
        title: "Search Payments",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-view-payment-search",
        subMenu: [],
      },
      {
        path: "/warranty-and-service/fw-hw-versions",
        title: "FW/HW Versions",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-view-hw-fw-versions",
        subMenu: [],
      },
      {
        path: "/warranty-and-service/grace-days",
        title: "Grace Days",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-assign-gracedays",
        subMenu: [],
      },
      {
        path: "",
        title: "L4 Assessments",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-view-work-order-historical-assessment",
        subMenu: [
          {
            path: "warranty-and-service/work_order/start/assessment",
            title: "Start Assessments",
            permission: "can-view-work-order-start-assessment",
          },
          {
            path: "warranty-and-service/work_order/historical/assessment",
            title: "Historical Assessed WO",
            permission: "can-view-work-order-historical-assessment",
          },
          {
            path: "warranty-and-service/work_order/dashboard/assessment",
            title: "Dashboard",
            permission: "can-view-work-order-assessment-dashboard",
          },
        ],
      },
      {
        path: "/warranty-and-service/tenant-part-inventory",
        title: "Tenant part Inventory",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-view-tenant-part-inventory",
        subMenu: [],
      },
      {
        path: "",
        title: "Aftersales Commissions",
        icon: "group_icon",
        class: "",
        moduleName: "Warranty and Service",
        permission: "",
        subMenu: [
          {
            path: "warranty-and-service/aftersales-commission",
            title: "Setup Aftersales Commission",
            permission: "can-view-aftersales-commissions",
          },
          {
            path: "warranty-and-service/aftersales-commission/payout",
            title: "View Commissions Payout",
            permission: "can-view-aftersales-commission-payout",
          },
          // {
          //   path: '',
          //   title: 'Aftersales Commissions Reports',
          //   permission: '',
          // },
        ],
      },
      {
        path: "/warranty-and-service/reports",
        title: "Reports",
        icon: "reports_icons",
        class: "",
        moduleName: "Warranty and Service",
        permission: "can-view-warranty-and-service-reports",
        subMenu: [],
      },
      {
        path: "/warranty-and-service/search",
        title: "Search",
        icon: "search_icon",
        class: "",
        moduleName: "Warranty and Service",
        subMenu: [],
      },
    ],
    softlaunch: [
      {
        path: "/soft-launch",
        title: "Dashboard",
        icon: "dashboard_icon",
        permission: "can-view-soft-launch-dashboard",
        class: "",
        moduleName: "Soft Launch",
        subMenu: [],
      },
      {
        path: "/soft-launch/project",
        title: "Projects",
        icon: "group_icon",
        class: "",
        moduleName: "Soft Launch",
        permission: "can-view-soft-launch",
        subMenu: [],
      },
    ],
    knowledge: [
      {
        path: "",
        title: "Pages",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Knowledge Base",
        permission: "",
        subMenu: [
          {
            path: "/knowledge-base/pages/create",
            title: "Create Page",
            icon: "",
            class: "",
          },
          {
            path: "/knowledge-base/pages",
            title: "Pages",
            icon: "",
            class: "",
          },
        ],
      },
    ],
    globalsettings: [
      {
        path: "/global-settings/inventory-products",
        title: "Global Manifest",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-inventory-products",
        subMenu: [],
      },
      {
        path: "/global-settings/content-management",
        title: "Content Management",
        icon: "group_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-content-management",
        subMenu: [],
      },
      {
        path: "/global-settings/netsuite-item",
        title: "Netsuite Item Management",
        icon: "group_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-netsuite-tem",
        isFeatureEnabled: featureFlags.enableNetsuitItemManagement,
        subMenu: [],
      },
      {
        path: "/global-settings/shipment-management",
        title: "Shipment Management",
        icon: "group_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "",
      },
      {
        path: "/global-settings/scheduled-jobs",
        title: "Scheduled Jobs",
        icon: "group_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-scheduled-jobs",
        subMenu: [],
      },
      {
        path: "",
        title: "Product Warranties",
        icon: "group_icon",
        class: "",
        permission: "can-view-product-warranties",
        moduleName: "Global Settings",
        subMenu: [
          {
            path: "/global-settings/global-product-warranties",
            title: "Global Product Warranties",
            icon: "",
            class: "",
            permission: "can-view-global-product-warranties",
          },
          {
            path: "/global-settings/tenant-product-warranties",
            title: "Tenant Product Warranties",
            icon: "",
            class: "",
            permission: "can-view-tenant-product-warranties",
          },
        ],
      },
      {
        path: "products",
        title: "Products",
        icon: "group_icon",
        class: "",
        permission: "can-view-global-products",
        moduleName: "Global Settings",
        subMenu: [
          {
            path: "/global-settings/global-products",
            title: "Global Products",
            icon: "",
            class: "",
            permission: "can-view-global-products",
          },
          {
            path: "/global-settings/global-product-attributes",
            title: "Global Product Attributes",
            icon: "",
            class: "",
            permission: "can-view-global-product-attributes",
          },
          {
            path: "/global-settings/global-product-types",
            title: "Global Product Types",
            icon: "",
            class: "",
            permission: "can-view-global-product-type",
          },
        ],
      },
      {
        path: "/global-settings/tenants",
        title: "Tenants",
        icon: "group_icon",
        class: "",
        permission: "can-view-tenant-types",
        moduleName: "Global Settings",
        subMenu: [
          {
            path: "/global-settings/tenants",
            title: "Tenants",
            icon: "",
            class: "",
            permission: "can-view-tenants",
          },
          {
            path: "/global-settings/tenant-types",
            title: "Tenant Types",
            icon: "",
            class: "",
            permission: "can-view-tenant-types",
          },
        ],
      },
      {
        path: "/global-settings/multiple-tenant-users",
        title: "All Users Management",
        icon: "group_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-multiple-tenant-users",
        subMenu: [],
      },
      {
        path: "/global-settings/integrations-dashboard",
        title: "Integrations Dashboard",
        icon: "group_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-global-integrations-dashboard",
      },
      {
        path: "/global-settings/fault-action-setup",
        title: "Product Faults/Actions setup",
        icon: "group_icon",
        class: "",
        permission: "can-create-faults",
      },
      {
        path: "",
        title: "Tokens",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-token-history",
        subMenu: [
          {
            path: "/global-settings/token/history",
            title: "Token History",
            permission: "can-view-token-history",
            icon: "",
            class: "",
          },
          {
            path: "/global-settings/token/generate-token",
            title: "Generate Token",
            permission: "can-generate-tokens",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "",
        title: "Credentials",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-credential-history",
        subMenu: [
          {
            path: "/global-settings/credential/history",
            title: "Credentials History",
            permission: "can-view-credential-history",
            icon: "",
            class: "",
          },
          {
            path: "/global-settings/credential/generate-credentials",
            title: "Generate Credential",
            permission: "can-generate-credentials",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "",
        title: "Test Cases",
        icon: "escalate_icon",
        moduleName: "Global Settings",
        class: "",
        permission: "can-setup-test-cases",
        subMenu: [
          {
            path: "/global-settings/testflow",
            title: "Ticket Test Flow",
            icon: "",
            class: "",
          },
          {
            path: "/global-settings/workordertestflow",
            title: "Work Orders",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "/global-settings/automatic-payouts",
        title: "Automatic Commission Payouts Setup",
        icon: "escalate_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-automatic-commission-payouts-setup",
        subMenu: [],
      },
      {
        path: "/global-settings/bgyr-segmentation",
        title: "BGYR segmentation",
        icon: "group_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-bgyr-segmentation",
        subMenu: [],
      },
      {
        path: "/global-settings/fieldforce-segmentation",
        title: "Field Force Segmentation",
        icon: "group_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-fieldforce-segmentation",
        subMenu: [],
      },
      {
        path: "/global-settings/release-notes",
        title: "Release Notes",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Global Settings",
        permission: "can-view-release-note",
        subMenu: [],
      },
    ],
    communication: [
      {
        path: "/communication/dashboard",
        title: "Dashboard",
        icon: "dashboard_icon",
        class: "",
        moduleName: "Communication",
        permission: "",
        subMenu: [],
      },
      {
        path: "/communication/broadcast",
        title: "Broadcast Management",
        icon: "register_icon",
        class: "",
        moduleName: "Communication",
        permission: "",
        subMenu: [
          {
            path: "/communication/broadcast",
            title: "Broadcast",
            permission: "can-view-broadcast",
            icon: "",
            class: "",
          },
          {
            path: "/communication/broadcast/sms-template",
            title: "SMS Template",
            permission: "can-view-communication-sms-template",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "",
        title: "Campaign Management",
        icon: "register_icon",
        class: "",
        moduleName: "Communication",
        permission: "can-view-campaign",
        subMenu: [
          {
            path: "/communication/campaign",
            title: "Campaign",
            permission: "",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "/communication/crm-template",
        title: "CRM SMS Management",
        icon: "register_icon",
        class: "",
        moduleName: "Communication",
        permission: "",
        subMenu: [
          {
            path: "/communication/crm-template/list",
            title: "CRM SMS Template",
            permission: "",
            icon: "",
            class: "",
          },
        ],
      },
      {
        path: "/communication/escalation",
        title: "Escalations",
        icon: "escalate_icon",
        class: "",
        moduleName: "Communication",
        permission: "can-view-sms-escalation",
        subMenu: [
          {
            path: "/communication/escalation/sms-template-escalation",
            title: "SMS Template",
            permission: "",
            icon: "",
            class: "",
          },

          {
            path: "/communication/escalation/crm-template-escalation",
            title: "CRM SMS Template",
            permission: "",
            icon: "",
            class: "",
          },
        ],
      },
    ],
    ticketing: [
      {
        path: "",
        title: "Admin Panel",
        icon: "ticketing_setting_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-view-ticketing-admin-panel",
        subMenu: [


      {
        path: "/ticketing/issues",
        title: "All Issues",
        icon: "ticket_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-view-all-ticketing-issues",
        subMenu: [],
      },
      // {
      //   path: "/ticketing/projects",
      //   title: "Projects",
      //   icon: "project_icon",
      //   class: "",
      //   moduleName: "Ticketing",
      //   permission: "can-manage-ticket-projects",
      //   subMenu: [],
      // },
      {
        path: "/ticketing/workflows",
        title: "Workflows",
        icon: "workflow_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-manage-ticket-workflows",
        subMenu: [],
      },
      {
        path: "/ticketing/calltoactionrules",
        title: "Calls To Action",
        icon: "action_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-manage-ticket-calls-to-action",
        subMenu: [],
      },
      {
        path: "/ticketing/dispositionsmaps",
        title: "Disposition Mapping",
        icon: "call_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-manage-ticket-disposition-mapping",
        subMenu: [],
      },
      {
        path: "/ticketing/tickettype",
        title: "Ticket Types",
        icon: "ticket_type_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-manage-ticket-types",
        subMenu: [],
      }, {
        path: "/ticketing/teams",
        title: "Teams",
        icon: "teams_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-view-ticket-teams",
        subMenu: [],
      },
      {
        path: "/ticketing/customfields",
        title: "Custom Fields",
        icon: "custom_fields_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-manage-ticket-custom-fields",
        subMenu: [],
      },
      {
        path: "/ticketing/ticketstatuses",
        title: "Ticket Statuses",
        icon: "ticket_status_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-manage-ticket-status",
        subMenu: [],
      },
      {
        path: "/ticketing/requesttypes",
        title: "Request Types",
        icon: "issue_type_icon",
        class: "",
        moduleName: "Ticketing",
        permission: "can-manage-request-types",
        subMenu: [],
      },
        ]
      },

    ]

  },
  moduleNav: {
    logo: [
      {
        path: "/crm/customers",
        title: "CRM",
        icon: "crm",
        class: "",
      },
      {
        path: "/inventory/dashboard",
        title: "Inventory",
        icon: "inventory",
        class: "",
      },
      {
        path: "/fieldforce",
        title: "Field Force / RTM",
        icon: "rtm",
        class: "",
      },
      {
        path: "icons",
        title: "Demand Planning",
        icon: "planning",
        class: "",
      },
      {
        path: "/soft-launch/project",
        title: "Soft Launch",
        icon: "soft_launch",
        class: "",
      },
      {
        path: "/setup/rates",
        title: "Setup",
        icon: "setup",
        class: "",
      },
      {
        path: "/knowledge-base/pages",
        title: "Knowledge Base",
        icon: "knowledge",
        class: "",
      },
      {
        path: "/identity-management/users",
        title: "Identity & Access Management",
        icon: "identity",
        class: "",
      },
      {
        path: "maps",
        title: "Document Management",
        icon: "document",
        class: "",
      },
      {
        path: "/reporting/reports",
        title: "Reports & Analytics",
        icon: "reports",
        class: "",
      },
      {
        path: "/finance/manual-payments",
        title: "Finance",
        icon: "document",
        class: "",
      },
      {
        path: "/ticketing",
        title: "Ticketing",
        icon: "document",
        class: "",
      },
    ],
  },
  subNav: {
    crm: {
      customer: [
        {
          path: "/crm/customers",
          title: "Customers",
          icon: "",
          class: "",
        },
        {
          path: "/crm/customers/registration",
          title: "Customer Registration",
          icon: "",
          class: "",
        },
      ],
    },
  },
};

export const modulesArray = [
  {
    name: "CRM",
    icon: "assets/images/identity_manag.svg",
    route: "/crm/customers",
    moduleName: "CRM",
  },
  {
    name: "Inventory",
    icon: "assets/images/inventory.svg",
    route: "/inventory/stocking-points",
  },
  {
    name: "Soft Launch",
    icon: "assets/images/soft_launch.svg",
    route: "/soft-launch/project",
  },
  {
    name: "Setup",
    icon: "assets/images/setup.svg",
    route: "/setup/dashboard",
  },
  {
    name: "Field Force",
    icon: "assets/images/rtm.svg",
    route: "/fieldforce",
  },
  {
    name: "Identity Management",
    icon: "assets/images/crm.svg",
    route: "/identity-management/users",
  },
  {
    name: "Knowledge Base",
    icon: "assets/images/knowledge.svg",
    route: "/knowledge-base/pages",
  },
  {
    name: "Demand Planning",
    icon: "assets/images/planning.svg",
    route: "",
  },
  {
    name: "Document Management",
    icon: "assets/images/document.svg",
    route: "",
  },
  {
    name: "Reporting",
    icon: "assets/images/reports.svg",
    route: "/reporting/reports",
  },
  {
    name: "Finance",
    icon: "assets/images/finance.svg",
    route: "/finance/manual-payments",
  },
  {
    name: "Warranty And Service",
    icon: "assets/images/warranty.svg",
    route: "/warranty-and-service/ticket/list",
  },
  {
    name: "Communications",
    icon: "assets/images/communication.png",
    route: "/communication/broadcast",
  },
  {
    name: "Ticketing",
    icon: "assets/images/ticketing.png",
    route: "/ticketing/myissues",
  },
];
