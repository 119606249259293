import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {debounce} from 'lodash';
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentUserDetails} from "@appModels/ticketing/current.user.details";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {ProjectService} from "@appServices/core-ticketing/project-service";
import {BaseCheckboxPojo} from "@appModels/ticketing/base.checkbox.pojo";
import {TeamDetails} from "@appModels/ticketing/team.details";
import {BasePojo} from "@appModels/ticketing/base-pojo";

@Component({
  moduleId: module.id,
  templateUrl: './update-team-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./update-team-component.css', '../../../tailwind.css']
})
export class UpdateTeamComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private teamsService: TeamsService,
    private projectService: ProjectService,
    private router: Router,
    private route:ActivatedRoute
  ) {
    this.searchUsers = debounce(this.searchUsers, 500)


    this.teamId = this.route.snapshot.paramMap.get('id');

    this.teamsService.getTeam(this.teamId).subscribe(x=> {
      this.team = x;
      this.mainLoading = false;

      this.teamsService.getRepairCentresWithoutTeam().subscribe(x => {

        this.repairCentres.push({id:0, name:'No Service Center'});
        if (this.team.repairCentreId != null) {
          this.repairCentres.push({id:this.team.repairCentreId, name:this.team.repairCentreName});
        }
        this.repairCentres = this.repairCentres.concat(x);
      })
    });


    this.teamsService.searchRoles().subscribe(x => {
      this.roles = x;
      this.rolesLoading = false;
    })


  }

  mainLoading = true;
  rolesLoading = true;
  teamId = null;
  selectedRole : any;

  roles = []

  userSearch = null;
  loading = false;
  userSearchResults : CurrentUserDetails[] = [];
  repairCentres :BasePojo[] = [];

  team : TeamDetails = {
    name:null,
    users: [],
    projects : []
  }

  projects :BaseCheckboxPojo[] =  [];

  searchUsers() {
    if (this.userSearch == null || this.userSearch.length == 0) {
      this.userSearchResults = [];
    }
    if (this.userSearch == null || this.userSearch == undefined || this.userSearch.length < 3) {
      return;
    }

    this.userSearchResults = [];

    this.loading = true;
    this.teamsService.queryUsers(this.userSearch, true).subscribe( x => {
      this.userSearchResults = x;
      this.loading = false;
    })
  }

  addUser(user : CurrentUserDetails) {
    //if already added
    for(let i = 0 ; i< this.team.users.length ; i++) {
      if (this.team.users[i].id == user.id){
        return;
      }
    }

    this.team.users.push(user);
    this.userSearchResults = this.userSearchResults.filter(x =>
      x.id != user.id
    )
  }

  removeUser(user: CurrentUserDetails) {
    this.team.users = this.team.users.filter(x =>
      x.id != user.id
    )
  }

  updateTeam(){

    if (this.team.name == undefined || this.team.name == null || this.team.name.length < 2) {
      this.toastrService.error("Please enter a longer name");
      return;
    }

    if (this.team.users == null || this.team.users == undefined || this.team.users.length == 0) {
      this.toastrService.error("Please add at least one user");
      return;
    }

    this.mainLoading = true;

    this.teamsService.updateTeam(this.team.id, this.team).subscribe(x=> {
      this.toastrService.success("Team Updated Successfully");
      this.router.navigateByUrl(`/ticketing/teams`);
    })

  }



  selectRole() {

    console.log(this.selectedRole)
    this.rolesLoading = true;
    this.teamsService.getUsersFromRole(this.selectedRole).subscribe(x => {
      this.rolesLoading = false;
      let newUsers = [];
      for (let i = 0; i < x.length; i++) {
        let exists = false;
        for (let j = 0 ; j < this.team.users.length; j++) {
          if (x[i].id == this.team.users[j].id) {
            exists = true;
          }
        }
        if (!exists) {
          newUsers = newUsers.concat(x[i]);
        }
      }
      if (newUsers.length > 0) {
        this.toastrService.success("Assigned " + newUsers.length + " new users")
      } else {
        this.toastrService.error("All the users from this role are already added")
      }

      if (this.team.roles.filter(x => x.id == this.selectedRole).length == 0) {
        for (let i = 0; i < this.roles.length; i++) {
          if (this.roles[i].id == this.selectedRole) {
            this.team.roles.push(this.roles[i]);
          }
        }
      }

      this.team.users = this.team.users.concat(newUsers);
    })
  }


  removeRole(roleId:number) {
    this.rolesLoading = true;
    this.team.roles = this.team.roles.filter(role => {return role.id != roleId});
    this.teamsService.getUsersFromRole(roleId).subscribe(x => {
      this.rolesLoading = false;
      let usersToRemove = x.map(user => {return user.id});
      console.log(usersToRemove)
      this.team.users = this.team.users.filter(user => {
        return !usersToRemove.includes(user.id)
      })
      this.toastrService.success("Removed " + usersToRemove.length + " users linked to role");
    })
  }



}
