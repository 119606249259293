<div class="flex flex-row justify-start my-8 mx-4">
  <div class="flex flex-row   gap-3 justify-start">
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light cursor-pointer hover:text-dlight ease-in-out duration-300" routerLink="/ticketing/teams">
      Teams
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      View Team
    </div>
  </div>

</div>

<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>

<ng-container *ngIf="!mainLoading">

<div class="w-2/3 m-auto">
  <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
    <div class="font-semibold text-lg">General Details</div>

    <form>
      <div class="mt-6">
        <label class="label_title">Name
          <span *ngIf="team.name == null || team.name.length < 3" class="text-red-500">*</span>
          <span *ngIf="team.name != null && team.name.length >= 3" class="text-green-500">*</span>
        </label>
        <input class=" form-input form-input-focus" placeholder="Enter a name"  [(ngModel)]="team.name" name="name"/>
      </div>

      <div class="mt-3 flex flex-row justify-start gap-3">
        <label class="label_title">Color Code
          <span *ngIf="team.colorCode == null || team.colorCode.length < 3" class="text-red-500">*</span>
          <span *ngIf="team.colorCode != null && team.colorCode.length >= 3" class="text-green-500">*</span>
        </label>
        <input  type="color" class="color-picker"  [(ngModel)]="team.colorCode" name="colorCode"/>
      </div>
    </form>
  </div>
</div>

<div class="w-2/3 m-auto mt-8">
  <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
    <div class="font-semibold text-lg">Users</div>

    <form>
      <div class="mt-6 ">
        <div >
          <label class="label_title">Search for User
            <span *ngIf="userSearch == null || userSearch.length < 3" class="text-red-500">*</span>
            <span *ngIf="userSearch != null && userSearch.length >= 3" class="text-green-500">*</span>
          </label>
          <input (keyup)="searchUsers()" class="form-input form-input-focus" placeholder="Enter name or email"  [(ngModel)]="userSearch" name="userSearch"/>
        </div>

      </div>
    </form>




    <div  class="w-3/4 m-auto">
      <div class="grid grid-cols-2 py-3 mt-5 ticket-row ">
        <div class="col-span-1 m-auto">
          NAME
        </div>
        <div class="col-span-1 m-auto">
          EMAIL
        </div>


      </div>

      <div class="mt-3" *ngIf="loading">
        <div class="flex flex-row justify-center items-center w-full  gap-4">
          <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
        </div>
      </div>



      <div class="mb-5">
        <div class="text-lg font-semibold mt-3" *ngIf="userSearchResults.length > 0">Search Results</div>
        <div style="max-height:500px;overflow-y:scroll">
          <div *ngFor="let user of userSearchResults" (click)="addUser(user)"  class="cursor-pointer grid grid-cols-2 py-3 ticket-row small-box-shadow" >
            <div class="col-span-1 flex flex-row justify-start items-center gap-2 pl-2">
                        <span *ngIf="user.firstName" class="flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                              [ngStyle]="{'background':user.colorCode}">
                      {{user.initials}}
                      </span>
              <span class="font-semibold ">{{user.firstName + ' ' + user.lastName}}</span>
            </div>
            <div class="col-span-1 m-auto">
              {{user.email}}
            </div>

          </div>
        </div>
      </div>


      <div class="text-lg font-semibold" *ngIf="team.users.length > 0">Selected Users</div>
      <div style="max-height:500px;overflow-y:scroll">
        <div *ngFor="let user of team.users"   class="cursor-pointer grid grid-cols-2 py-3 ticket-row small-box-shadow "  >
          <div class="col-span-1 flex flex-row justify-start items-center gap-2 pl-2">
                      <span *ngIf="user.firstName" class="flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                            [ngStyle]="{'background':user.colorCode}">
                    {{user.initials}}
                    </span>
            <span class="font-semibold ">{{user.firstName + ' ' + user.lastName}}</span>
          </div>
          <div class="col-span-1 flex flex-row items-center justify-between ">
            <div>{{user.email}}</div>
            <!--          <svg xmlns="http://www.w3.org/2000/svg" fill="green" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0m6.891 9.973-7.539 7.539a1.7 1.7 0 0 1-1.2.497c-.453 0-.879-.176-1.2-.497L5.11 13.67a1.7 1.7 0 0 1-.497-1.2c0-.453.177-.88.497-1.2a1.7 1.7 0 0 1 1.2-.497c.453 0 .88.177 1.2.497l2.642 2.642 6.339-6.339a1.7 1.7 0 0 1 1.2-.497 1.698 1.698 0 0 1 1.2 2.897"/></svg>-->
            <button (click)="removeUser(user)"  type="button" class="btn rounded-full w-8 h-8 btn-outline-dlight p-0 "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M17.25 20.25h-9c-.825 0-1.5-.675-1.5-1.5V6h12v12.75c0 .825-.675 1.5-1.5 1.5zm3-14.25h-15m5.25 0V4.5c0-.45.3-.75.75-.75h3c.45 0 .75.3.75.75V6m-2.25 11.25V9m3 8.25V9m-6 8.25V9"/></svg></button>
          </div>


        </div>
      </div>

    </div>

  </div>
</div>


  <div class="w-2/3 m-auto mt-8">
    <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
      <div class="font-semibold text-lg">Roles</div>

      <form>
        <div class="mt-6">
          <div class="flex flex-row justify-start items-center gap-3">
            <label class="label_title">Select A Role To Autopopulate Users</label>
            <div>
              <span *ngIf="rolesLoading" class="m-auto  inline-block h-8 w-8 animate-spin rounded-full border-4 border-[#f1f2f3] border-l-dlight align-middle"></span>
            </div>
          </div>

          <div class="mt-1">
            <select (change)="selectRole()" class="select_input form-input form-input-focus" [(ngModel)]="selectedRole" [ngModelOptions]="{standalone: true}"  placeholder="Select a Role..."  name="selectedRole">
              <option value="" disabled>{{'Please select a role' | translate}}</option>
              <option [value]="role.id" *ngFor="let role of roles"> {{role.name}}</option>
            </select>
          </div>
        </div>
      </form>

      <div *ngIf="team.roles.length > 0" class="mt-3">
        <div class="text-base font-semibold">Linked Roles: </div>
        <div class="mt-3"></div>
        <div>
          <div  *ngFor="let role of team.roles" class="grid grid-cols-2 items-center mt-1">
            <div class="col-span-1">{{role.name}}</div>
            <div class="col-span-1"><button (click)="removeRole(role.id)"  type="button" class="btn rounded-full w-8 h-8 btn-outline-dlight p-0 "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" width="24" height="24"><path fill="none" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" d="M17.25 20.25h-9c-.825 0-1.5-.675-1.5-1.5V6h12v12.75c0 .825-.675 1.5-1.5 1.5zm3-14.25h-15m5.25 0V4.5c0-.45.3-.75.75-.75h3c.45 0 .75.3.75.75V6m-2.25 11.25V9m3 8.25V9m-6 8.25V9"/></svg></button></div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="w-2/3 m-auto mt-8">
  <div style="border-radius:20px" class="bg-white box-shadow-pop p-4">
    <div class="font-semibold text-lg">Link Service Center</div>

    <div class="mt-6">
      <label class="label_title">Select a Service Center</label>
      <select class="select_input form-input form-input-focus" placeholder="Select Service Center..."
              [(ngModel)]="team.repairCentreId" name="selectedServiceCenter">
        <option value="" disabled>{{'Please select Service Center' | translate}}</option>
        <option [value]="repairCentre.id" *ngFor="let repairCentre of repairCentres"> {{repairCentre.name}} </option>
      </select>
    </div>
  </div>


  <div class="flex flex-row w-full mt-5 justify-center">
    <button (click)="updateTeam()" type="button" class="mt-10 btn btn-success btn-lg">Update Team</button>
  </div>
</div>

</ng-container>

