import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "mpesa-statement-summary",
  template: `
    <h4 class="m-t-20 m-b-10">

      {{ "Mpesa Statement Summary" | translate }}
    </h4>
    <h4>Latest log: {{latestLogMessage}}</h4>
    <table style="background: #f7f7fc; border-radius: 3px">
      <thead>
        <tr>
          <th>{{ "TRANSACTION TYPE" | translate }}</th>
          <th>{{ "AMOUNT PAID IN" | translate }}</th>
          <th>{{ "AMOUNT PAID OUT" | translate }}</th>
          <th>{{ "CUSTOMER NAME" | translate }}</th>
           <th>{{ "MOBILE" | translate }}</th>
           <th>{{ "STATEMENT PERIOD" | translate }}</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let mpesa of mpesaStatementSummary; let i = index">
          <td [ngStyle]="i === mpesaStatementSummary.length - 1 ? {'color': '#FF4612',  'font-weight': 'bold'} : {}">
            <b> {{ mpesa.transactionType || "--" }} </b>
          </td>
          <td [ngStyle]="i === mpesaStatementSummary.length - 1 ? {'color': '#FF4612',  'font-weight': 'bold'} : {}">{{ mpesa.paidIn || "--" }}</td>
          <td [ngStyle]="i === mpesaStatementSummary.length - 1 ? {'color': '#FF4612',  'font-weight': 'bold'} : {}">{{ mpesa.paidOut || "--" }}</td>
          <td [ngStyle]="i === mpesaStatementSummary.length - 1 ? {'color': '#FF4612',  'font-weight': 'bold'} : {}">{{ mpesa.customerName || "--" }}</td>
          <td [ngStyle]="i === mpesaStatementSummary.length - 1 ? {'color': '#FF4612',  'font-weight': 'bold'} : {}">{{ mpesa.mobileNumber || "--" }}</td>
          <td [ngStyle]="i === mpesaStatementSummary.length - 1 ? {'color': '#FF4612',  'font-weight': 'bold'} : {}">{{ mpesa.statementPeriod || "--" }}</td>
        </tr>
         <tr>
            <td>
              <a><b> SCORE </b></a>
            </td>
            <td></td>
            <td><b>NULL</b></td>
        </tr>
      </tbody>
    </table>`,
  encapsulation: ViewEncapsulation.None,
})
export class MpesaStatementSummaryComponent implements OnInit {
  @Input() mpesaStatementSummary: any[] = [];
  @Input() mpesaStatementCheck: any;
  @Input() componentMode: any;
  @Input() statusName: any;
  @Input() latestLogMessage: string;

  constructor() {}

  ngOnInit() {}
}
