import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {TeamListRow} from "@appModels/ticketing/team.list.row";
import {DispositionsmapService} from "@appServices/core-ticketing/dispositionsmap-service";
import {DispositionsMap} from "@appModels/ticketing/dispositions-map";
import {DispositionsMapDisposition} from "@appModels/ticketing/dispositions-map-disposition";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {RequestTypeService} from "@appServices/core-ticketing/request-type.service";

@Component({
  moduleId: module.id,
  templateUrl: './dispositionsmap-create-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./dispositionsmap-create-component.css', '../../../tailwind.css']
})
export class DispositionsmapCreateComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private dispositionService: DispositionsmapService,
    private teamService: TeamsService,
    private requestTypeService: RequestTypeService
  ) {

    this.requestTypeService.getRequestTypes(true).subscribe(x => {
      this.requestTypes = x;
      this.mainLoading = false;
    })

    this.teamService.getTeams().subscribe(x => {
      this.teams = x.content;
    });

    this.dispositionService.getCallGroups().subscribe(x => {
      this.groupList = x;
    })

  }

  newDisposition :DispositionsMapDisposition = {
    group : null,
    level1 : null,
    level2 : null,
    level3 : null,

  }

  searching = false;
  selectedGroup() {
    this.searching = true;

    this.newDisposition.level1 = null;
    this.newDisposition.level2 = null;
    this.newDisposition.level3 = null;

    this.dispositionService.getLevel1Calls(this.newDisposition.group).subscribe(x => {
      this.level1List = x;
      this.searching = false;
    })
  }

  selectedLevel1() {

    this.newDisposition.level2 = null;
    this.newDisposition.level3 = null;

    this.searching = true;
    this.dispositionService.getLevel2Calls(this.newDisposition.group, this.newDisposition.level1).subscribe(x => {
      this.level2List = x;
      this.searching = false;
      if (this.level2List != null && this.level2List.length == 1) {
        this.newDisposition.level2 = "";
        this.level3List = [""];
        this.newDisposition.level3 = "";
      }
    })
  }

  selectedLevel2() {

    this.newDisposition.level3 = null;

    this.searching = true;
    this.dispositionService.getLevel3Calls(this.newDisposition.group, this.newDisposition.level1, this.newDisposition.level2, null).subscribe(x => {
      this.level3List = x;
      this.searching = false;

      //level3 are empty string when none present
      if (this.level3List != null && this.level3List.length == 0) {
        this.newDisposition.level3 = this.level3List[0];
      }
    })
  }

  map : DispositionsMap = {
    id:0,
    name:null,
    requestTypeId:null,
    teamId:null,
    dispositionsMapDisposition:[]
  }

  groupList :string[] = [];
  level1List : string[] = [];
  level2List : string[] = [];
  level3List : string[] = [];

  teams : TeamListRow[];
  requestTypes : BasePojo[];

  mainLoading = true;
  loadingAdding = false;
  addDisposition() {

    if (this.newDisposition.level1 == null || this.newDisposition.level1 == undefined ) {
        this.toastrService.error("Please select level 1")
      return;
    }

    if (this.newDisposition.level2 == null && this.level2List.length > 1) {
      this.toastrService.error("Please select level 2")
      return;
    }

    if (this.newDisposition.level3 == null && this.level3List.length > 1) {
      this.toastrService.error("Please select level 3")
      return;
    }

    if (this.map.dispositionsMapDisposition.some(x => x.group == this.newDisposition.group
      && x.level1 == this.newDisposition.level1
     && x.level2 == this.newDisposition.level2
    && x.level3 == this.newDisposition.level3)) {
      this.toastrService.error("You already added this disposition")
      return;
    }

    this.loadingAdding = true;

    this.dispositionService.validateAddDisposition(this.newDisposition, null).subscribe(x => {
      this.loadingAdding = false;
      if (x.code == 200) {
        this.map.dispositionsMapDisposition.push(JSON.parse(JSON.stringify(this.newDisposition)));
        this.newDisposition = {
          group : null,
          level1 : null,
          level2 : null,
          level3 : null,
        }
      } else {
        this.toastrService.error(x.message);
      }
    })


  }

  removeDisposition(disposition : DispositionsMapDisposition) {
    let disp = JSON.parse(JSON.stringify(disposition));

    let temp = [];
    for (let i = 0 ; i < this.map.dispositionsMapDisposition.length ; i++) {

      if (! (this.map.dispositionsMapDisposition[i].group == disposition.group
        && this.map.dispositionsMapDisposition[i].level1 == disposition.level1
        && this.map.dispositionsMapDisposition[i].level2 == disposition.level2
        && this.map.dispositionsMapDisposition[i].level3 == disposition.level3
      )) {
        temp.push(JSON.parse(JSON.stringify(this.map.dispositionsMapDisposition[i])))
      }
    }
    this.map.dispositionsMapDisposition = JSON.parse(JSON.stringify(temp));
  }


  loadingCreate = false;
  createMapping() {
    if (this.map.name == null || this.map.name == undefined || this.map.name.length < 3) {
      this.toastrService.error("Please enter a longer name");
      return;
    }
    if (this.map.requestTypeId == null || this.map.requestTypeId == undefined ) {
      this.toastrService.error("Please select Ticket Type")
      return;
    }

    if (this.map.dispositionsMapDisposition.length == 0) {
      this.toastrService.error("Please add at least one disposition")
      return;
    }



    this.loadingCreate = true;
    this.dispositionService.createMap(this.map, true).subscribe(x => {
      this.toastrService.success("Map Created !");
      this.router.navigateByUrl(`/ticketing/dispositionsmaps`);
      this.loadingCreate = false;
    })

  }

}
